import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  @ViewChild('secText') text: ElementRef;

  ngAfterViewInit() {
    const textLoad = () => {
      setTimeout(() => {
        this.text.nativeElement.textContent = 'soy ingeniero en Software!';
      }, 0);

      setTimeout(() => {
        this.text.nativeElement.textContent = 'soy Desarrollador Web!';
      }, 4000);

      setTimeout(() => {
        this.text.nativeElement.textContent = 'soy Desarrollador Angular!';
      }, 8000);
    }
    setInterval(textLoad, 12000)

  }
}

