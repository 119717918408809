import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { environment } from 'src/environments/environment';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LSMService {

  constructor(private http : HttpClient) { }

  getLSM(): Observable<any> {
    return this.http.get(`${environment.firebaseConfig.databaseURL}sw.json`)
  }
}
