import { Component, OnInit } from '@angular/core';
import { LSMService } from "../../apis/lsm.service";
import { lastValueFrom } from 'rxjs';
import { LsmInterface } from "../../apis/lsm.interface";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  public senas: LsmInterface[] = [];
  public errors: any;
  public loading = true;
  public search = '';
  searchForm: FormGroup;
  originalSenas: LsmInterface[] = [];

  constructor(private apiSW: LSMService, private fb: FormBuilder) { }

  ngOnInit(): void {
    setTimeout(
      () => {
        this.getLSM().then(() => this.loading = false);
      }, 2000
    );
    this.searchForm = this.fb.group({
      search: ['']
    });
  }


  async getLSM(): Promise<any> {
    try {
      const response: any = await lastValueFrom(this.apiSW.getLSM());
      this.senas = response;
      this.originalSenas = [...response];
    } catch (error: any) {
      this.errors = `El servidor no responde, intente más tarde. ${error.statusText}`;
    }
  }

  buscar() {
    const searchTerm = this.searchForm.value.search;
    if (searchTerm.trim() !== '') {
      this.senas = this.originalSenas.filter((senas: LsmInterface) => {
        return senas.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
    } else {
      this.senas = [...this.originalSenas];
    }
  }
}
