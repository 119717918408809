<h1>
  Aplicaciones de la Lengua de Señas en el uso de Software: avances
  tecnológicos.
</h1>
<div class="cargado" *ngIf="loading">
  <nz-spin nzSimple></nz-spin>
</div>
<div *ngIf="errors">
  <nz-result nzStatus="500" nzTitle="500" nzSubTitle="{{ errors }}">
  </nz-result>
</div>

<nz-list nzGrid *ngIf="!loading">
  <form [formGroup]="searchForm" (ngSubmit)="buscar()" class="form">
    <div nz-space nzAlign="center">
      <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
        <input
          type="text"
          nz-input
          placeholder="Buscar..."
          formControlName="search"
        />
      </nz-input-group>
      <ng-template #suffixIconButton>
        <button nz-button nzType="primary" nzSearch type="submit">
          <span nz-icon nzType="search"></span>
        </button>
      </ng-template>
    </div>
  </form>
  <nz-divider></nz-divider>
  <div nz-row [nzGutter]="16">
    <div
      nz-col
      [nzSpan]="8"
      [nzXXl]="8"
      [nzXl]="8"
      [nzLg]="8"
      [nzMd]="8"
      [nzSm]="12"
      [nzXs]="24"
      *ngFor="let item of senas"
    >
      <nz-list-item>
        <nz-card
          [nzTitle]="item.name"
          style="box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5)"
        >
          <video src="assets/video/{{ item.video }}" controls></video>
        </nz-card>
      </nz-list-item>
    </div>
  </div>
</nz-list>
