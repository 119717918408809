<div>
  <h1 class="animate__animated animate__backInDown">¡Bienvenido!</h1>
  <div class="circle">
    <div class="container">
      <span class="text first-text">¡Hola, </span>
      <span class="text sec-text" #secText> Codeaf 😀!</span>
    </div>
  </div>
  <div class="animate__animated animate__fadeInTopLeft">
    <img class="animate__animated animate__fadeInTopLeft codeaf" src="assets/CODEAF.png">
  </div>
  <nz-divider nzDashed [nzText]="text">
    <ng-template #text>
      <span nz-icon nzType="audit" nzTheme="outline"></span>
      Biografía
    </ng-template>
  </nz-divider>

  <nz-list nzItemLayout="vertical">
    <nz-list-item>
      <nz-list-item-extra>
        <img class="animate__animated animate__fadeInTopLeft" alt="Daniel" src="assets/Dani.jpg" />
      </nz-list-item-extra>
      <p class="animate__animated animate__fadeInLeftBig">¡Hola, mi nombre es Daniel Calderón González!</p>
      <p class="animate__animated animate__fadeInLeftBig">
        Creación de mi página web: (Codeaf) - Es un proyecto de carácter tecnológico, proveniente de mi creatividad
        para proponer una palabra que tuviera un sentido vernáculo a mi cultura que hiciera referencia a un "código
        sordo", esto en español. No obstante en una extensión del mercado laboral su traducción vendría a
        ser "code deaf".
      </p>
      <p class="animate__animated animate__fadeInLeftBig">Este proyecto consiste en utilizar la tecnología con miras de creación para un sistema de software, el
        cual, Sordos nativos profesionales en la materia, se sirvan de su idioma, la lengua de señas para crear y
        mostrar nuevas aplicaciones de esta lengua a través del mismo.</p>
      <p class="animate__animated animate__fadeInLeftBig">Si es de tu interés, dale clic: <a routerLink="LSM-SW"> <span nz-typography
            nzType="success">"LSM-SW"</span></a> , saludos.</p>
    </nz-list-item>
  </nz-list>
</div>
