import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);
import { NZ_I18N, es_ES } from 'ng-zorro-antd/i18n';

import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from "ng-zorro-antd/form";
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzListModule } from "ng-zorro-antd/list";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import {NzResultModule} from "ng-zorro-antd/result";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzSpaceModule} from "ng-zorro-antd/space";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NoopAnimationsModule,
    NzLayoutModule,
    NzBreadCrumbModule,
    NzMenuModule,
    NzCollapseModule,
    NzTimelineModule,
    NzDividerModule,
    NzFormModule,
    NzButtonModule,
    NzIconModule,
    NzListModule,
    NzCardModule,
    NzProgressModule,
    NzTabsModule,
    NzRadioModule,
    NzTypographyModule,
    NzSpinModule,
    NzResultModule,
    NzInputModule,
    NzSpaceModule
  ],
  exports: [
    NoopAnimationsModule,
    NzLayoutModule,
    NzBreadCrumbModule,
    NzMenuModule,
    NzCollapseModule,
    NzTimelineModule,
    NzDividerModule,
    NzFormModule,
    NzButtonModule,
    NzIconModule,
    NzListModule,
    NzCardModule,
    NzProgressModule,
    NzTabsModule,
    NzRadioModule,
    NzTypographyModule,
    NzSpinModule,
    NzResultModule,
    NzInputModule,
    NzSpaceModule
  ],
  providers: [{ provide: NZ_I18N, useValue: es_ES }]
})
export class ZorroModule { }
