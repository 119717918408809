export const environment = {
    firebaseConfig: {
        apiKey: "AIzaSyBqIfNmqVYoGNauSXZ1mWXPn1tb29YNYsI",
        authDomain: "codeaf-bb991.firebaseapp.com",
        databaseURL: "https://codeaf-lsm-3bcaf-default-rtdb.firebaseio.com/",
        projectId: "codeaf-bb991",
        storageBucket: "codeaf-bb991.appspot.com",
        messagingSenderId: "141981602452",
        appId: "1:141981602452:web:7977ec0024c137ebcb9a81",
        measurementId: "G-BN3FXMDDK7"
    },
    production: false
};
