<nz-layout>
  <nz-header>
    <div class="logo">
      <a routerLink="/">
        <img src="assets/CODEAF-Blanco.png" alt="Codeaf" />
      </a>
    </div>
    <ul nz-menu nzTheme="dark" nzMode="horizontal">
      <li nz-menu-item routerLink="/">
        Home <span nz-icon nzType="home" nzTheme="outline"></span>
      </li>
      <li nz-menu-item routerLink="LSM-SW">
        LSM-SW <span nz-icon nzType="apartment" nzTheme="outline"></span>
      </li>
    </ul>
  </nz-header>
  <nz-content>
    <div class="inner-content">
      <router-outlet></router-outlet>
    </div>
  </nz-content>
  <nz-footer>Codeaf ©{{ date | date : "YYYY" }}</nz-footer>
</nz-layout>
